import React from 'react';
import Main from '../components/pages/Main';
import Page404 from '../components/pages/Page404';

const Pagina404 = () => (
  <Main page="404">
    <Page404 />
  </Main>
);

export default Pagina404;

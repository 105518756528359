import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import GlazedBackgroundBlue from '../../static/images/fundo-redondo-vidro-azul.svg';
import GlazedBackgroundPurple from '../../static/images/fundo-redondo-vidro-roxo.svg';
import GlazedBackgroundWhite from '../../static/images/fundo-redondo-vidro-branco.svg';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '70px',
    height: '70px',

    '& .emoji': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});

const background = {
  blue: GlazedBackgroundBlue,
  purple: GlazedBackgroundPurple,
  white: GlazedBackgroundWhite,
};

const GlazedEmoji = ({ img, color, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={background[color]} alt="Círculo de vidro atrás do emoji" />
      <img src={img} className="emoji" {...rest} />
    </Box>
  );
};

GlazedEmoji.defaultProps = {
  img: '',
  rest: '',
  color: 'purple',
};

GlazedEmoji.propTypes = {
  img: PropTypes.string,
  rest: PropTypes.string,
  color: PropTypes.string,
};

export default GlazedEmoji;

import React from 'react';
import { Typography, Box, Grid, makeStyles } from '@material-ui/core';
import ContentWrap from '../atoms/ContentWrap';
import GlazedEmoji from '../atoms/GlazedEmoji';
import StyledButton from '../atoms/StyledButton';
import ContentSpacer from '../molecules/ContentSpacer';
import EmojiEyes from '../../static/images/emoji-olhos.png';
import AbductedMan from '../../static/images/ilustracao-homem-abduzido.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    padding: '190px 0 150px',

    '& .MuiTypography-h3': {
      fontWeight: 400,
      margin: '35px 0 15px'
    },
    '& .MuiTypography-subtitle1, & .MuiTypography-button, & .MuiTypography-h6': {
      color: theme.palette.midGrey.main,
      
      '& strong': {
        color: theme.palette.error.main,
      },
    },
    '& ul': {
      margin: '15px 0 40px',
      
      '& li': {
        marginTop: '15px',
      },
    },

    '@media screen and (max-width: 959px)': {
      padding: '115px 0 100px',
      gridTemplateColumns: 'auto',
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    gap: '25px',

    '& .MuiBox-root': {
      display: 'grid',
      alignItems: 'center',
    },
    '& .MuiTypography-subtitle1': {
      letterSpacing: '0.3em',
    }
  },
  image: {
    textAlign: 'right',

    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  }
}));

const SiteMap = () => {
  const classes = useStyles();

  return (
    <>
      <ContentWrap>
        <Box container className={classes.root}>
          <Box>
            <Box className={classes.error}>
              <GlazedEmoji img={EmojiEyes} alt="Emoji de olhos" />

              <Box>
                <Typography variant="subtitle1" component="p">OPA!</Typography>
                <Typography variant="button">Código de erro: 404</Typography>
              </Box>
            </Box>

            <Typography variant="h3" component="h1">
              Não foi possível acessar esta página no momento
            </Typography>

            <Typography variant="h6" component="h2">
              Mas não se preocupe pois esta página está em boa companhia com outras
              <strong> coisas que não existem </strong>
              aqui na Conube, como por exemplo:
            </Typography>

            <ul>
              <Typography variant="subtitle1" component="li">
                Desorganização;
              </Typography>
              <Typography variant="subtitle1" component="li">
                Papelada;
              </Typography>
              <Typography variant="subtitle1" component="li">
                Burocracia;
              </Typography>
              <Typography variant="subtitle1" component="li">
                Perda de tempo.
              </Typography>
            </ul>

            <StyledButton
              text="Voltar para o início"
              variant="contained"
              color="primary"
              width="290px"
              component="a"
              href="/"
            />
          </Box>

          <Box className={classes.image}>
            <img src={AbductedMan} alt="Homem sendo abduzido" />
          </Box>
        </Box>
      </ContentWrap>
    </>
  );
};

export default SiteMap;

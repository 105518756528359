import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '130px',
    width: '1px',
    '@media screen and (max-width: 900px)': {
      height: '50px',
    },
  },
});

const ContentSpacer = () => {
  const classes = useStyles();
  return <Box className={classes.root} />;
};

export default ContentSpacer;
